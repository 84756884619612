import { Route, Routes, createBrowserRouter } from 'react-router-dom'
import Health from '../pages/health'
import MainPage from '../App'
import React from 'react';

const router = createBrowserRouter([
    {
      path: "/health",
      element: <Health/>,
    },
    {
      path:"/chat",
      element: <MainPage/>
    },
    {
      path:"/",
      element: <MainPage/>
    }
  ]);
  
export default router