export default {
  styleOverrides: {
    root: {
      fontFamily: 'inherit',
      fontStyle: 'inherit',
      fontSize: 'inherit',
      fontWeight: '500',
      height: 'inherit',
      width: 'inherit'
    }
  }
}
