import { Box, Avatar, Typography, Grid, MenuItem, Menu, IconButton } from '@mui/material'
import { getTimeString, stringToAvatar } from '../../lib/'
import { useStyles } from '../../style/styles'
import { useEffect, useState } from 'react'
import { MessagingConstants } from '../../lib/enums'
import { formatDate, splitUserArn } from '../../lib/messageUtils'
import { IProps } from './types'
import { MessagePayload, Channel } from '../Chat/types'
import React from 'react'

const ChatMessage = (props: IProps) => {
  const classes = useStyles()
  const { channelArn,
          userArn,
          prevMessage,
          message,
          isEndedConversation,
          isLastMessage,
          userType,
          handleDeleteMessage,
          connections,
          timezone,
          channelMetadata,
          endOrReenableActionDoer
        } = props
  const prevSender = prevMessage?.Sender || null
  const dateTime = new Date(message.CreatedTimestamp)
  const isSelf = splitUserArn(message.Sender.Arn) === userArn
  const sender = message.Sender.Name
  const content = message.Content
  const isRedacted = message.Redacted
  const isPrevMessageRedacted = prevMessage?.Redacted
  const [timeElapsed, setTimeElapsed] = useState<string>('')
  const [isEndedOrReenabled, setIsEndedOrReenabled] = useState<boolean>(false)
  const [isPrevEndedOrReenabled, setIsPrevEndedOrReenabled] = useState<boolean>(false)
  const [indicatorMessage, setIndicatorMessage] = useState<string | null>(null)
  const isNotSameFromPrev = ((prevSender && prevSender.Arn !== message.Sender.Arn) || prevSender === null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const [isBlocked, setIsBlocked] = useState<Boolean>(false)

  useEffect(() => {
    setIsBlocked(
      (channelMetadata?.BlockedBy || channelMetadata?.BlockedBy?.trim() !== '' )
      // (connections.isBlocked || connections.isBlockedLink)
      )
  }, [channelMetadata])

  useEffect(() => {
    setIsEndedOrReenabled(false)
    checkMessage(message, true)
    if(prevMessage) {
      checkMessage(prevMessage, false)
    }
  }, [message, prevMessage, timezone])

  useEffect(() => {
    const interval = setInterval(() => {
      updateTimeElapsed()
    }, 1000)
    updateTimeElapsed()
    return () => clearInterval(interval);
  }, [message])

  const updateTimeElapsed = () => {
    const currentTime = new Date().getTime()
    const elapsedTime = Math.floor((currentTime - dateTime.getTime()) / 1000)
    setTimeElapsed(getTimeString(elapsedTime))
  }

  let metaData = null
  let isRemovedFromScreen:boolean = false
  if(message.Metadata) {
    metaData = JSON.parse(message.Metadata)
    isRemovedFromScreen = metaData?.[MessagingConstants.REMOVED_FROM_SCREEN_TIMESTAMP] ? true : false
  }

  const checkMessage = (message: MessagePayload, isNotPrevMessage: boolean) => {
    if(message?.Metadata) {
      const metaData = JSON.parse(message.Metadata)
      if(metaData.ActionType === MessagingConstants.END_CONVERSATION_MARKER) {
        if(isNotPrevMessage) {
          setIndicatorMessage(`Conversation Ended. ${formatDate(metaData.EndConversationTimestamp, timezone)}`)
          setIsEndedOrReenabled(true)
        } else {
          setIsPrevEndedOrReenabled(true)
        }
      } else if(metaData.ActionType === MessagingConstants.REENABLE_CONVERSATION_MARKER) {
        if(isNotPrevMessage) {
          setIndicatorMessage(`Conversation Re-enabled. ${formatDate(metaData.ReEnableConversationTimestamp, timezone)}`)
          setIsEndedOrReenabled(true)
        } else {
          setIsPrevEndedOrReenabled(true)
        }
      }
    }
  }

  const formatChatName = (text: string) => {
    return (!text.includes('@') && !text.includes(' ')) ? text : text.split(/[@ ]/).filter(Boolean)
  }

  const formatMessage = (text: string): { lines: string[], brCount: number } => {
    const lines = text.split('\n');
    const brCount = lines.length - 1;
    return { lines, brCount };
  }

  const { lines, brCount } = formatMessage(content);

  const renderMenu = channelArn ? (
    <Menu
        keepMounted
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => {setAnchorEl(null)}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ zIndex: 9999, fontSize: '14px', fontFamily: 'Poppins', lineHeight: '22px' }}
      >
        <MenuItem
          disabled={isRemovedFromScreen}
          onClick={() => {
            setAnchorEl(null)
            handleDeleteMessage(channelArn, message.MessageId, message.Content, splitUserArn(message.Sender?.Arn), MessagingConstants.REMOVE_FROM_SCREEN)
          }}>
          <Typography style={{ color: '#F42A3F' }}>
            Delete
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() =>{
            setAnchorEl(null)
            handleDeleteMessage(channelArn, message.MessageId, message.Content, splitUserArn(message.Sender?.Arn), MessagingConstants.REMOVE_FOR_EVERYONE)
          }}>
          <Typography style={{ color: '#F42A3F'}}>
            Delete for everyone
          </Typography>
        </MenuItem>
      </Menu>
  ) : <> </>

  return (channelArn) ? (
    <>
    { !isRedacted && (
    <div className={classes.messageBodyContainer}>
      {/* for status indicators: ended/reenabled convo */}
      { isEndedOrReenabled ? (
        <>
          <Grid item className={classes.contentCenter}>
            <div className={classes.endOrReenabledMarker}>
              { indicatorMessage }
            </div>
          </Grid>
        </>
      ) : (
        <>
        {/* for normal messages */}
        { isSelf ? (
          <>
          <div className={classes.timeMessageContainer}>
            <Grid
              container
              direction='column'>
              <Grid
                item
                container
                justifyContent='right'
                alignContent='right'
                sx={{ width: '100%', marginBottom: '8px' }}>
                <Typography
                  sx={{ color: '#888', fontSize: '12px' }}>
                  { isNotSameFromPrev || isPrevEndedOrReenabled ? 'You, ' + timeElapsed : timeElapsed }
                </Typography>
              </Grid>
              <Grid item
                container
                justifyContent='right'
                alignContent='right'
                sx={{ width: '100%' }}>
                <div className={props.platform === 'mobile' ? classes.messageContentSelf : classes.messageContentOther}>
                  { isRemovedFromScreen && isSelf ? (
                  <>
                    <Typography className={classes.deletedMessageText}>
                      {/* This message has been removed from your screen. */}
                      This message has been deleted.
                    </Typography>
                      <button
                        color="primary" className={classes.undoButton}
                        onClick={() => {
                          if (handleDeleteMessage) {
                            handleDeleteMessage(channelArn, message.MessageId, message.Content, splitUserArn(message.Sender?.Arn), MessagingConstants.UNDO_REMOVE_MESSAGE)
                          }
                          setAnchorEl(null)
                        }}> UNDO
                      </button>
                    </>
                  ) : (
                    <Typography className={props.platform === 'mobile' ? classes.messageTextSelf : classes.messageTextOther}
                      sx={{
                        fontSize: '14px',
                        wordWrap: 'break-word',
                        maxWidth: '300px',
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                        {lines.map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            {index < brCount && <br />} {/* Render <br> for each '\n' in the original text */}
                          </React.Fragment>
                        ))}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: '10px', position: 'relative' }} >
            <div  className={classes.avatar}>
            {/* { isNotSameFromPrev && !isPrevEndedOrReenabled && ( */}
            { isNotSameFromPrev || isPrevEndedOrReenabled ? (
              <Avatar {...stringToAvatar(sender)} />
            ): (<></>)}
            </div>
            <div   className={classes.avatar} style={{ position: 'absolute', bottom: 0, left: 0, right: 0, textAlign: 'center' }}>
              <IconButton
                onClick={(e) => { setAnchorEl(e.currentTarget) }}
                style={{
                  padding: '0px',
                  fontSize: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <img
                  src="/images/ellipsis-h-unsent.svg"
                  alt="Unsend message"
                  width={15}
                  height={15}
                />
              </IconButton>
            </div>
            {renderMenu}
          </div>
        </>
        ) : (
          <>
          { !isRemovedFromScreen && ( <>
          <div className={classes.avatar}>
            {/* { isNotSameFromPrev && !isPrevEndedOrReenabled ? ( */}
            { isNotSameFromPrev || isPrevEndedOrReenabled ? (
                <Avatar {...stringToAvatar(sender)} />
              ) : (<></>)
            }
          </div>
          <div className={classes.timeMessageContainer}>
            <Grid
              container
              direction='column'
              sx={{ marginLeft: '10px' }}
              >
              <Grid
                item
                container
                justifyContent='left'
                alignContent='left'
                sx={{ width: '100%', marginBottom: '8px' }}>
                <Typography
                  sx={{ color: '#888', fontSize: '12px' }}>
                  { isNotSameFromPrev || isPrevEndedOrReenabled ? formatChatName(sender)[0] + ', ' + timeElapsed : timeElapsed }
                </Typography>
              </Grid>
              <Grid item
                container
                justifyContent='left'
                alignContent='left'
                sx={{ width: '100%' }}>
                <div className={classes.messageContentOther}>
                  <Typography className={classes.messageTextOther}
                    sx={{
                      fontSize: '14px',
                      wordWrap: 'break-word',
                      maxWidth: '300px',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                  {lines.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < brCount && <br />} {/* Render <br> for each '\n' in the original text */}
                    </React.Fragment>
                  ))}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          </>
        )}
        </>)}
        </>
      )}
    </div>
    )}

    <div>
      {isEndedConversation && isLastMessage && (
        <div className={classes.messageContentEndConversationWrapper}>
          <div className={classes.messageContentEndConversation}>
            <Typography className={classes.messageTextEndConversation}>
              {endOrReenableActionDoer == userArn ? (
                <>
                  This conversation has ended. If you wish to communicate once more, you can initiate the chat by sending another message.
                </>
              ) : (
                <>
                  This conversation has been ended. You can no longer send messages.
                </>
              )}
            </Typography>
          </div>
        </div>
      )}

      {/* {isLastMessage && (
        <> LAST MESSAGE   </>
      )} */}
      {/* { isBlocked && isLastMessage && (
        <div className={classes.messageContentEndConversationWrapper}>
          <div className={classes.messageContentEndConversation}>
            {connections.isBlocked ? (
              <Typography className={classes.messageTextEndConversation}>
                1 You have been blocked by this user.
              </Typography>
            ) : (
            isSelf && (channelMetadata?.BlockedBy !== null && channelMetadata?.BlockedBy == userArn ) ? (
              <Typography className={classes.messageTextEndConversation}>
                2 You have been blocked by this user.
              </Typography>
            ) : (
              <Typography className={classes.messageTextEndConversation}>
               3 You have blocked this user.
              </Typography>
              )
            )}
          </div>
        </div>
      )} */}
    </div>
  </>
  ) : <> </>
}

export default ChatMessage
