import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { IProps } from './types'
import { useState } from 'react'

const TermsOfService = (props: IProps) => {
    const { openPopIn, handleResponse, token } = props

    const [showValidationError, setShowValidationError] = useState(false)
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

    const checkAgreeTerms = () => {
        if(isCheckboxChecked) {
            setShowValidationError(false)
            handleResponse (true)
        } else {
            setShowValidationError(true)
        }
    }

    const handleCheckbox = (event: any) => {
        if(event.target.checked) {
            setShowValidationError(false)
        }

        setIsCheckboxChecked(event.target.checked)
    }

    return (
    <Dialog open={openPopIn} onClose={() => handleResponse(false)}>
        <DialogTitle>
        Terms of Service
        </DialogTitle>
        <DialogContent>
            <p> This messaging feature is powered by a third-party. By using messaging, you consent to the collection and use of information about you and the content of messages sent and received through this feature by JobTarget and the third-party vendor acting on our behalf. </p>
            <p>
                <input type="checkbox" id="agreeCheckbox" checked={isCheckboxChecked} onChange={handleCheckbox}/>
                <label htmlFor="agreeCheckbox"> By clicking "Agree", you agree to JobTarget's </label>
                <a href="https://www.jobtarget.com/terms-of-use" target="_blank">Terms of Use</a> and <a href="https://www.jobtarget.com/privacy-policy" target="_blank">Privacy Policy.</a>
            </p>
            {showValidationError && (
                <div className="error">
                    <p style={{ color: 'red', fontSize: '12px', marginTop: '5px'
                    }}> Please agree to the Terms of Use and Privacy Policy to proceed.
                    </p>
                </div>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => checkAgreeTerms()}> Agree </Button>
        </DialogActions>
    </Dialog>
  )
}

export default TermsOfService
