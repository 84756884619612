import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import TimezoneSelect, { ITimezone } from 'react-timezone-select';
import { IProps } from './types';
import { TIMEZONE, TIMEZONE_ABBREV, TIMEZONE_ALTNAME, updateUserTimezone } from '../../lib/messageUtils';

const Timezone = (props: IProps) => {
  const { initialValue, messagingUserId, openPopIn, onClose, onChange } = props
  const now = new Date();
  const isDST = (new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Detroit',
    timeZoneName: 'short'
  }).formatToParts(now).find(part => part.type === 'timeZoneName')?.value === 'EDT');

  const defaultTimezone: ITimezone = {
    value: "America/Detroit",
    label: isDST ? "(GMT-4:00) Eastern Time (EDT)" : "(GMT-5:00) Eastern Time (EST)",
    offset: isDST ? -4 : -5,
    abbrev: isDST ? "EDT" : "EST",
    altName: isDST ? "Eastern Daylight Time" : "Eastern Standard Time",
  };

  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(initialValue ? initialValue : defaultTimezone)

  const handleSave = async () => {
    if(selectedTimezone) {
      const timezone = (selectedTimezone as { value: string }).value
      const timezoneAbbrev = (selectedTimezone as { abbrev: string }).abbrev
      const timezoneAltName = (selectedTimezone as { altName: string }).altName
      await updateUserTimezone(messagingUserId, timezone)
      onChange(timezone)
      localStorage.setItem(TIMEZONE, timezone)
      localStorage.setItem(TIMEZONE_ALTNAME, timezoneAltName)
      localStorage.setItem(TIMEZONE_ABBREV, timezoneAbbrev)
    }
    
    onClose()
  }

  const handleCancel = () => {
    onClose()
  };

  const formatTimezoneDisplay = (timezone: ITimezone) => {
    const timezoneValue = (timezone as { value: string }).value
    const timezoneAltName = (timezone as { altName: string }).altName
    const timezoneAbbrev = (timezone as { abbrev: string }).abbrev

    const timezoneValueFromLocalStorage = localStorage.getItem(TIMEZONE) || ''
    const timezoneAltNameFromLocalStorage = localStorage.getItem(TIMEZONE_ALTNAME) || ''
    const timezoneAbbrevFromLocalStorage = localStorage.getItem(TIMEZONE_ABBREV) || ''

    let output = timezoneValue ?
                `Timezone: ${timezoneValue}\n Alt Name: ${timezoneAltName} (${timezoneAbbrev})` :
                `Timezone: ${timezoneValueFromLocalStorage}\n Alt Name: ${timezoneAltNameFromLocalStorage} (${timezoneAbbrevFromLocalStorage})`

    return output
  }

  return (
    <Dialog open={openPopIn} onClose={onClose}>
      <DialogTitle>
         Timezone Settings 
      </DialogTitle>
      <DialogContent style={{ minWidth: '300px' }}>
        <p>Please select your preferred timezone:</p>
        <div className="select-wrapper">
          <TimezoneSelect
            labelStyle='abbrev'
            value={selectedTimezone}
            onChange={setSelectedTimezone}
          />
        </div>
        <h4>Selected Timezone:</h4>
        <div
          style={{
            backgroundColor: "#ccc",
            padding: "20px",
            borderRadius: "5px",
            maxWidth: "600px",
            overflowX: "auto",
          }}
        >
          <pre
            style={{
              margin: "0 20px",
              fontWeight: 500,
              fontFamily: "monospace",
            }}
          >
           <p> {formatTimezoneDisplay(selectedTimezone)} </p>
            
          </pre>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', margin: '5px' }}>
        <Button onClick={handleSave} variant="contained" color="primary"> Save </Button>
        <Button onClick={handleCancel} >Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default Timezone;
