/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { v4 } from 'uuid'
import AWS from 'aws-sdk'
import {
  LogLevel,
  ConsoleLogger,
  DefaultMessagingSession,
  MessagingSessionConfiguration
} from 'amazon-chime-sdk-js'
import { getMessagingSessionEndpoint } from '../redux/utils/ChimeMessagingUtils'
import { userArn } from '../lib/messageUtils'
import { MessageResponse } from '../components/Chat/types'
class MessagingService {
  _session: any;
  sessionId: any;
  _logger: any;
  _messageUpdateCallbacks: any;
  _endpoint: any;
  loggedInUser: any;
  didStopPromise: Promise<void>;
  didStopResolver: (() => void) | undefined;

  constructor() {
    this._session = null;
    // this.sessionId = v4();
    this.sessionId = null;
    this._logger = new ConsoleLogger('SDK Chat Demo', LogLevel.INFO);
    this._messageUpdateCallbacks = [];
    this.loggedInUser = null;
    this.didStopPromise = new Promise((resolve) => {
      this.didStopResolver = resolve;
    });
  }

  messageObserver = {
    messagingSessionDidStart: () => {
      console.log('Messaging Connection started!')
    },
    messagingSessionDidStartConnecting: () => {
      console.log('Messaging Connection connecting')
    },
    messagingSessionDidStop: () => {
      console.log('Messaging Connection received DidStop event');
      if (this.didStopResolver) {
        this.didStopResolver();
      }
    },
    messagingSessionDidReceiveMessage: (message: MessageResponse) => {
      console.log('Messaging Connection received message')
      this.publishMessageUpdate(message)
    }
  };

  setMessagingEndpoint(userId: string) {
    getMessagingSessionEndpoint()
    .then(async response => {
      this._endpoint = response?.Endpoint?.Url;
      this.sessionId = v4()
      const user = await userArn(userId)
      const sessionConfig = new MessagingSessionConfiguration(
        // await userArn(userId),
        user,
        this.sessionId,
        response?.Endpoint?.Url,
        new AWS.ChimeSDKMessaging()
      )
      this._session = new DefaultMessagingSession(
        sessionConfig,
        this._logger
      )
      this._session.addObserver(this.messageObserver)
      await this._session.start()
    }).catch(err => {
      console.log('Error in setting Messaging endpoint')
    })
  }

  async connect(userId: string) {
    await this.close()
    this.loggedInUser = userId
    this.setMessagingEndpoint(userId)
  }

  async close() {
    if(this._session) {
      try {
        await this._session.stop();
        console.log('Attempting to stop the Messaging session...');
        await this.didStopPromise;
        console.log('The messaging session has observed stop session');
      } catch (err) {
        console.error('Failed to stop Messaging Session.', err);
      }
    }
  }

  subscribeToMessageUpdate(callback: (arg0: MessageResponse)=> Promise<void>) {
    console.log('Message listener subscribed!')
    this._messageUpdateCallbacks.push(callback)
  }

  unsubscribeFromMessageUpdate(callback: (arg0: MessageResponse)=> Promise<void>) {
    const index = this._messageUpdateCallbacks.indexOf(callback)
    if (index !== -1) {
      this._messageUpdateCallbacks.splice(index, 1)
    }
  }

  publishMessageUpdate(message: MessageResponse) {
    console.log(`Sending message update to listeners!`)
    for (let i = 0; i < this._messageUpdateCallbacks.length; i += 1) {
      const callback = this._messageUpdateCallbacks[i]
      callback(message)
    }
  }
}

const messagingService = new MessagingService()
//ToDo execute something here before exporting.

export default messagingService
