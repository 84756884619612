import { configureStore, combineReducers } from '@reduxjs/toolkit';
import channelReducer from './channel/reducer';

const rootReducer = combineReducers({
  channels: channelReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;