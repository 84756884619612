import axios from 'axios'
 
const apiURL = process.env.REACT_APP_MESSAGING_API
 
export const environment = process.env.REACT_APP_ENV
 
const axiosInstance = axios.create({
  baseURL: apiURL,
});

export default axiosInstance