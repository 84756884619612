import ChimeMessaging from 'aws-sdk/clients/chimesdkmessaging'

let chimeMessaging: ChimeMessaging | null = null

async function chimeMessagingClient() : Promise<ChimeMessaging> {
  // if (chimeMessaging == null) {
  //   chimeMessaging = new ChimeMessaging()
  // }
  // return chimeMessaging
  return new ChimeMessaging()
}

async function getMessagingSessionEndpoint() : Promise<any> {
  const request = (await chimeMessagingClient()).getMessagingSessionEndpoint()
  const response = await request.promise()
  return response
}

export {
  getMessagingSessionEndpoint
}
