export enum MessagingConstants {
    CANDIDATE = "CANDIDATE",
    END_CONVERSATION_MARKER = "end_conversation",
    RECRUITER = "RECRUITER",
    REENABLE_CONVERSATION_MARKER = "reenable_conversation",
    REMOVE_FOR_EVERYONE = "remove_for_everyone",
    REMOVE_FROM_SCREEN = "remove_from_screen",
    REMOVED_FOR_EVERYONE_TIMESTAMP = "removedForEveryoneTimeStamp",
    REMOVED_FROM_SCREEN_TIMESTAMP = "removedFromScreenTimestamp",
    UNDO_REMOVE_MESSAGE = "undoTimestamp",
}