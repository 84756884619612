import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { IProps } from './type'
import { useState, useEffect } from 'react'

const ReEnableChatPrompt = (props: IProps) => {
  const { openPopIn, handleResponse, isEndedConversation } = props

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  useEffect(() => {
    // can add a logs here for debugging purposes
}, [openPopIn, showConfirmationDialog, isEndedConversation])
  

  const handleSendMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  // Only show confirmation dialog if the conversation has ended
  if (isEndedConversation) {
    setShowConfirmationDialog(true)
  } else {
    handleResponse(false) // proceed without confirmation if conversation is open
  }
  }

  const handleSendConfirmationResponse = (confirm: boolean) => {
    // Handle the user's response from confirmation dialog
    setShowConfirmationDialog(false) // hide dialog after confirmation
    if (confirm) {
      handleResponse(true)  // Re-enable the chat and send the message
    } else {
      handleResponse(false) // Do not re-enable or send
    }
  }

  /*const handleConfirmationResponse = (confirm: boolean) => {
    // Handle the user's confirmation or cancellation
    setShowConfirmationDialog(true)
    if (confirm) {
      handleResponse(true) 
    } else {
      handleResponse(false) 
    }
  }*/

  return (
    <>
      <Dialog open={openPopIn} onClose={() => handleResponse(false)}>
        <DialogTitle>Sending Message Confirmation</DialogTitle>
        <DialogContent>
          <p>You previously closed this chat. Sending this message will re-open it. Are you sure you want to send the message?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { console.log('Clicked No on Main Dialog'); handleResponse(false) }}> No </Button>
          <Button onClick={() => { console.log('Clicked Yes on Main Dialog'); handleSendConfirmationResponse(true) }}> Yes </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReEnableChatPrompt
