const stringToAvatar = (name: string) => {
  const formattedName = name.toUpperCase()
    const stringToColor = (string: string) => {
      let hash = 0
      let i
  
      /* eslint-disable no-bitwise */
      for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
      }
  
      let color = '#'
  
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
      }
  
      return color
    }
    let initials = ''
    const nameParts = formattedName.split(' ').filter(part => part)
    // const initials = nameParts.length > 1 ? `${nameParts[0][0]}${nameParts[1][0]}` : nameParts[0][0]
    if (nameParts.length === 1) {
      initials = nameParts[0][0]
    } else if (nameParts.length > 1) {
      initials = `${nameParts[0][0]}${nameParts[1][0]}`
    }

    return {
      sx: {
        bgcolor: stringToColor(formattedName)
      },
      // children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
      children: initials.toUpperCase(),
    }
  }

export default stringToAvatar
  