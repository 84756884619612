export default {
  styleOverrides: {
    root: {
      padding: 2,
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontStyle: 'inherit'
    }
  }
}
