const getTimeString = (elapsedTime: number): string => {
  if (elapsedTime === null) {
    return ''
} else if (elapsedTime < 60) {
      return 'Just now'
  } else if (elapsedTime < 3600) {
      const minutes = Math.floor(elapsedTime / 60)
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`
  } else if (elapsedTime < 86400) {
      const hours = Math.floor(elapsedTime / 3600)
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`
  } else if (elapsedTime < 604800) {
      const days = Math.floor(elapsedTime / 86400);
      return days === 1 ? '1 day ago' : `${days} days ago`
  } else if (elapsedTime < 2419200) {
      const weeks = Math.floor(elapsedTime / 604800)
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`
  } else if (elapsedTime < 29030400) {
      const months = Math.floor(elapsedTime / 2419200)
      return months === 1 ? '1 month ago' : `${months} months ago`
  } else {
      const years = Math.floor(elapsedTime / 29030400)
      return years === 1 ? '1 year ago' : `${years} years ago`
  }
}

export default getTimeString