import { ChannelList, ChannelInfo } from './types';

const initialState: ChannelList = {
  channels: {},
};

const channelReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_CHANNEL_DATA':
      return {
        ...state,
        channels: action.payload,
      }
    case 'UPDATE_TERMS_AGREED': {
      const { channelName, termsAgreed } = action.payload;
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelName]: {
            ...state.channels[channelName],
            userInfo: {
              ...state.channels[channelName].userInfo,
              termsAgreed,
            },
          },
        },
      }
    }
    case 'UPDATE_CHANNEL_ARN': {
      const { channelName, channelArn } = action.payload;
        return {
          ...state,
          channels: {
            ...state.channels,
            [channelName]: {
              ...state.channels[channelName],
              channelArn: channelArn
            },
          },
      }
    }

    case 'UPDATE_MESSAGE_LIST': {
      const { channelName, messageList } = action.payload
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelName]: {
            ...state.channels[channelName],
            messageList: [...messageList]
          },
        },
      }
    }
    default:
      return state
  }
};

export default channelReducer;
