import { makeStyles } from '@mui/styles'
import './globalStyles.css'
import { relative } from 'path'

export const useStyles = makeStyles({
  chatDescription: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#3F4254',
    padding: '0px 24px 0px 24px',
    width: '100%'
  },
  chatDescriptionContainer: {
    backgroundColor: '#EAF3FC',
    // height: '54px',
    height: 'var(--default-description-height)',
    width: '100%',
    gap: '16px',
    top: '72px',
    left: '1px',
    padding: '0px, 24px, 0px, 24px',
  },
  chatPanel: {
    display: 'flex',
    flexDirection: 'column',
    msFlexDirection: 'column',
    WebkitFlexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1px',
    position: 'fixed',
    bottom: 3,
    right: 3,
    width: '385px',
    height: '500px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 11px 15px rgba(13, 45, 77, 0.05), 0px 24px 38px rgba(13, 45, 77, 0.035), 0px 9px 46px rgba(13, 45, 77, 0.03)',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    transition: 'transform 0.2s ease-in-out',
    transform: 'translateY(100%)',
    '&.show': {
      transform: 'translateY(0)'
    },
    zIndex: 9998,
    cursor: 'default'
  },
  chatNavbar: {
    // height: '70px',
    height: 'var(--default-navbar-height)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatBody: {
    flex: 1
  },
  innerChatBody: {
    marginLeft: '24px',
    marginRight: '24px'
  },
  chatListContainer: {
    width: '100%',
    // height: '40px',
    height: 'var(--default-body-height)',
    overflowY: 'scroll',
    borderBottom: '1px solid #F2F3F7',
    position: 'relative'
  },
  mobileChatListContainer: {
    width: '100%',
    height: 'var(--default-mobile-body-height)',
    overflowY: 'scroll',
    borderBottom: '1px solid #F2F3F7',
    position: 'fixed',
  },
  chatInputWeb: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    // padding: '10px 2px 10px 2px',
    backgroundColor: 'white',
    zIndex: 1000,
    // height: 'auto',
    maxHeight: '200px',
    margin: '4px',
    height: 'var(--default-input-height)'
  },
  chatInput: {
    display: 'flex',
    flexDirection: 'column',
    msFlexDirection: 'column',
    WebkitFlexDirection: 'column',
    alignItems: 'flex-start',
    WebkitAlignItems: 'flex-start',
    bottom: 10,
    // padding: '16px 0px',
    position: 'fixed',
    // height: '54px',
    height: 'var(--default-input-height)',
    width: '100%',
    flex: 'none',
    order: 5,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  contentCenter: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  deletedMessageText: {
    color: 'gray',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '22px',
  },
  endOrReenabledMarker: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#B5B5C3',
  },
  footer: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    boxSizing: 'border-box',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  messageContentEndConversationWrapper: {
    marginBottom: '16px',
    position: 'relative'
  },
  messageContentEndConversation: {
    display: 'flex',
    padding: '8px',
    fontSize: '14px',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#FFE2E5',
  },
  messageTextEndConversation: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    color: 'var(--danger-dark-802932, #802932)',
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '22px',
  },
  textCapitalize: {
    textTransform: 'capitalize'
  },
  primaryText: {
    color: '#2F86DD',
    fontSize: '12px'
  },
  messageContainer: {
    // display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '8px',
    marginRight: '10px',
    marginLeft: '10px'
  },
  avatar: {
    // position: 'relative',
    width: '40px',
    height: '40px',
    // marginRight: '16px'
  },
  messageContentSelf: {
    display: 'flex',
    flexDirection: 'row',
    msFlexDirection: 'row',
    WebkitFlexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px',
    gap: '8px',
    background: '#2F86DD',
    borderRadius: '12px',
    border: '1px solid #1E6DBB',
    maxWidth: 'max-content',
    fontSize: '14px'
  },
  messageContentOther: {
    display: 'flex',
    flexDirection: 'row',
    msFlexDirection: 'row',
    WebkitFlexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px',
    gap: '8px',
    borderRadius: '12px',
    border: '1px solid #C0DAF5',
    maxWidth: 'max-content',
    fontSize: '14px'
  },
  messageTextSelf: {
    color: '#FFFFFF',
    fontSize: '14px'
  },
  messageTextOther: {
    color: 'black',
    fontSize: '14px'
  },
  badgeNumber: {
    position: 'relative',
    top: '-20px',
    right: '-8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: 'white',
    fontSize: '10px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  messageBodyContainer: {
    display: 'flex',
    marginBottom: '8px'
  },
  normalText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
  },
  timeMessageContainer: {
    width: '100%'
  },
  undoButton: {
    backgroundColor: '#ffffff',
    border: '0px',
    color: '#0078d4',
    fontSize: '12px',
    padding: '2px 8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',

    '&:hover': {
      backgroundColor: '#f3f2f1',
      color: '#005a9e',
    },

    '&:active': {
      backgroundColor: '#e1dfdd',
      color: '#004578',
    },

    '&:focus': {
      outline: 'none',
      // boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
  },
  minimizedChat: {
    width: '100%',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#2F86DD',
    color: '#ffffff',
    padding: '10px 20px',
    position: 'fixed',
    bottom: 0,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  headerName: {
    cursor: 'default',
    flexGrow: 1,
    display: 'flex',
    textTransform: 'capitalize',
    fontSize: '19px',
    lineHeight: '24px',
    fontWeight: '500',
    width:  '50%',
    transition:   'width 0.3s ease-in',
    textAlign: 'left'
  },
  minimizedHeader: {
    cursor: 'default',
    flexGrow: 1,
    display: 'flex',
    textTransform: 'capitalize',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    width:  '50%',
    transition:   'width 0.3s ease-in',
    textAlign: 'left'
  },
  unreadBadge: {
    borderRadius: '1000px',
    background: 'var(--danger-default-f-64-e-60, #F64E60)',
    color: 'white',
    width: '20px',
    height: '20px',
    padding: '2px',
    fontSize: '12px',
    marginLeft: '5px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})
