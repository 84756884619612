import React, { useState } from 'react'
import { IProps } from './types'
import { useStyles } from '../../style/styles'
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import { MessagingConstants } from '../../lib/enums'
import messagingService from '../../services/MessagingService'
import { truncateText } from '../../lib/messageUtils'

function Navbar(props: IProps) {
    const classes = useStyles()
    const {
        receiver,
        job,
        userType,
        username,
        onEndConversation,
        isEndedConversation,
        token,
        connections,
        platform,
        onTimezoneClick,
        userArn,
        endOrReenableActionDoer,
        onMinimizeChat,
        channelMetadata
    } = props
    const isMinimized = false
    const onMinimize = () => {}
    const onExpandPanel = () => {}
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const isMenuOpen = Boolean(anchorEl)
    const [isExpanded, setIsExpanded] = useState(false)

    const handleChatClose = () => {
      // messagingService.close()
      window.parent.postMessage({ type: 'MESSAGING_CLOSE_IFRAME', token, candidateId: job?.candidate_id  }, '*');
      messagingService.close()
    }

    const handleMinimize = () => {
      onMinimizeChat()
    }

    const handleEndConversation = () => {
      onEndConversation(true)
      setAnchorEl(null)
    }

    const handleReEnableEndConversation = () => {
        onEndConversation(false)
        setAnchorEl(null)
    }

    const handleTimezone = () => {
      onTimezoneClick()
      setAnchorEl(null)
    }

    const getEllipsisIcon = () => {
      if(platform === 'mobile') {
        return connections?.isBlocked || connections?.isBlockedLink ? "/images/ellipsis-dark-disabled.svg" : "/images/ellipsis-dark.svg"
      } else {
        return connections?.isBlocked || connections?.isBlockedLink ? "/images/ellipsis-h-unsent.svg" : "/images/ellipsis-h.svg"
      }
    }

    const getCallIcon = () => {
      return platform !== 'mobile' && receiver?.mobile?.trim() ? "/images/Call.svg" : "/images/Call_Blocked.svg"
    }

    const handleCallIcon = async () => {
      const phone = receiver?.mobile
      if(phone?.replace(/[\s()]/g, '').trim()) {
        const callLink = `tel:${phone}`
        window.location.href = callLink
      }
    }

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isMenuOpen}
        onClose={() => {setAnchorEl(null)}}
        sx={{
          zIndex: 9999,
          fontSize: '14px',
          fontFamily: 'Poppins',
          lineHeight: '22px',
          padding: '4px, 1px, 4px, 1px'
        }}
      >
        {
          isEndedConversation ? (
          <MenuItem
            disabled={endOrReenableActionDoer == userArn ? false : true}
            onClick={handleReEnableEndConversation}
            sx={{ padding: '8px, 16px, 8px, 16px'}}
            >
            < Typography sx={{
              color: 'var(--primary-default-2-f-86-dd, #2F86DD)',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              padding: '8px, 16px, 8px, 16px'
            }} >
              Re-Enable Conversation
            </Typography>
          </MenuItem>
          ) : (
          <MenuItem
            onClick={handleEndConversation}>
            <Typography
              sx={{
                color: 'var(--danger-hover-focused-active-f-42-a-3-f, #F42A3F)',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              End Conversation
            </Typography>
          </MenuItem>
          )
        }

        <MenuItem
          onClick={handleTimezone}
        >
          <Typography
            sx={{
              color: 'var(--primary-text-color, #000)',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}
          >
            Settings
          </Typography>
        </MenuItem>
      </Menu>
    )
    const fColor = platform === 'mobile' ? 'inherit' : 'default'
    return (
        <>
        <div
          className={classes.chatNavbar}>
            <AppBar
                position="static"
                sx={{
                  borderRadius: '8px 8px 0px 0px',
                  boxShadow: 'none',
                  border: platform == 'mobile' ? '0.5px solid #eae8e4' : 'none',
                  backgroundColor: platform == 'mobile' ? 'white' : '#2f86dd',
                  color: fColor,
                  height: '100%'
                }}>
                <Toolbar
                    sx={{
                    height: '100%',
                    padding: '16px 16px 16px 24px',
                    borderRadius: 'inherit',
                    backgroundColor: 'inherit',
                    color: 'inherit',
                    display: 'flex', 
                    alignItems: 'center'
                }}>
                    <Box sx={{ flexGrow: 1, maxWidth: isMinimized ? '150px':'auto' }}>
                      <Tooltip title={username} arrow>
                        <Typography                  
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                cursor: 'default',
                                flexGrow: 1,
                                display: { xs: 'flex', sm: 'block' },
                                textTransform: 'capitalize',
                                fontSize: '19px',
                                lineHeight: '24px',
                                fontWeight: platform == 'mobile' ? '390' : '500',
                                fontFamily: 'Poppins',
                                width: isMinimized? '90%':'100%',
                                transition: isMinimized? 'width 0.3s ease-in' : 'width 0.3s ease-out',
                                textAlign: 'left',
                                padding: platform == 'mobile' ? '0px 0px 0px 40px' : '0px',
                            }}
                            // onClick={onMinimize}
                        > {truncateText(username, 30)}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'flex', gap: '0px' }}}>
                        {
                        !isMinimized && (
                          <>
                            { platform != 'mobile' ? (
                              <>
                                <IconButton
                                  onClick={handleMinimize}
                                  sx={{width:'38px', height: '38px', '&:hover': { cursor: 'pointer' }}}
                                  color="inherit"
                                  >
                                  <img
                                    src={"/images/compress-alt.svg"}
                                    alt={"Minimize"}
                                    width={18}
                                    height={20}
                                  />
                                </IconButton>
                                <IconButton
                                  color="inherit"
                                  onClick={handleCallIcon}
                                  sx={{width:'38px', height: '38px', '&:hover': { cursor: 'pointer' }}}
                                  disabled ={platform === "mobile" || !receiver?.mobile?.trim()}
                                  data-toggle="tooltip" data-placement="right" title="" data-original-title="Call this candidate"
                                >
                                  <img
                                    src={getCallIcon()}
                                    alt="Call this candidate"
                                    width={18}
                                    height={20}
                                  />
                                </IconButton>
                              </>
                            ) : null }
                              <IconButton
                                  onClick={(e) => { setAnchorEl(e.currentTarget) }}
                                  sx={{width:'38px', height: '38px', '&:hover': { cursor: 'pointer' }}}
                                  disabled={connections?.isBlocked || connections?.isBlockedLink}
                              >
                                <img
                                  src={getEllipsisIcon()}
                                  alt="View more options"
                                  width={18}
                                  height={20}
                                />
                              </IconButton>
                            </>
                        )
                        }
                        {platform != 'mobile' ? (
                        <IconButton
                            onClick={handleChatClose}
                            sx={{width:'38px', height: '38px', '&:hover': { cursor: 'pointer' }}}
                            color="inherit"
                            id="closeButton"

                        >
                        <img
                            src="/images/times.svg"
                            alt="Close chat"
                            width={18}
                            height={20}
                        />
                        </IconButton>
                        ): null}
                    </Box>
                </Toolbar>
            </AppBar>
              <>{renderMenu} </>
          </div>
        </>
    )
}

export default Navbar
