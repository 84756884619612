const getDisplayName = (user: any, termsOfServiceAgreed: boolean) => {
    const { first_name, last_name, email } = user
    let username = `${first_name} ${last_name}`
    if(termsOfServiceAgreed) {
      if(username && username.trim() !== '') return username
      else return email.split('@')[0]
    }
    return ''
  }

export default getDisplayName