export default {
  styleOverrides: {
    root: {
      '@media (orientation: landscape)': {
        minHeight: 0,
      },
      '@media (min-width: 600px)': {
        minHeight: 0
      },
      minHeight: 0,
      padding: 15,
      borderRadius: 'inherit',
      backgroundColor: 'inherit',
      color: 'inherit',
    }
  }
}
