import MuiToolbar from './MuiToolbar'
import MuiTypography from './MuiTypography'
import MuiAvatar from './MuiAvatar'
import MuiButtonBase from './MuiButtonBase'
import MuiIconButton from './MuiIconButton'
import MuiMenu from './MuiMenu'
import MuiMenuItem from './MuiMenuItem'
import MuiAppBar from './MuiAppBar'
import MuiInputBase from './MuiInputBase'

export default {
  MuiButtonBase,
  MuiIconButton,
  MuiTypography,
  MuiAppBar,
  MuiToolbar,
  MuiMenu,
  MuiMenuItem,
  MuiAvatar,
  MuiInputBase
}
