import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import getDisplayName from "../../lib/getDisplayName";
import { useStyles } from "../../style/styles";
import { IProps } from "./types";
import { truncateText } from "../../lib/messageUtils";
import messagingService from "../../services/MessagingService";

const MinimizedComponent = (props: IProps) => {
    const { receiver, token, channel, unreadCount, onMinimizeChat, job } = props
    const username = getDisplayName(receiver, true)
    const classes = useStyles()

    const handleMaximize = () => {
        onMinimizeChat()
    }

    const handleChatClose = () => {
        window.parent.postMessage({ type: 'MESSAGING_CLOSE_IFRAME', token, candidateId: job?.candidate_id  }, '*');
        messagingService.close()
    }

    return (
        <div className={classes.minimizedChat}>
            <Typography
                className={classes.minimizedHeader}
                variant="h6"
                noWrap
                component="div">
                { unreadCount > 0 ? (
                    <Tooltip title={username} arrow>
                        <Typography className={classes.flexContainer}>
                            {truncateText(username, 18)}
                            <span className={classes.unreadBadge}>
                                { unreadCount }
                            </span>
                       </Typography>
                    </Tooltip>
                ) :
                <>
                 { username.length > 20 ? (
                    <Tooltip title={username} arrow>
                        <Typography>
                            { truncateText(username, 20) }
                        </Typography>
                    </Tooltip>
                 ): <> <Typography> { username } </Typography></>
                 }
                </>
                }
            </Typography>
            <Box sx={{ display: { xs: 'flex', md: 'flex', gap: '0px' }}}>
                <IconButton
                    onClick={handleMaximize}
                    sx={{width:'32px', height: '32px', '&:hover': { cursor: 'pointer' }}}
                    color="inherit">
                    <img
                        src={"/images/expand-alt.svg"}
                        alt={"Expand"}
                        width={14}
                        height={14}
                    />
                </IconButton>
                <IconButton
                    onClick={handleChatClose}
                    sx={{width:'32px', height: '32px', '&:hover': { cursor: 'pointer' }}}
                    color="inherit"
                    id="closeButton">
                    <img
                        src="/images/times.svg"
                        alt="Close chat"
                        width={14}
                        height={14}
                    />
                </IconButton>
            </Box>
        </div>
    )
}

export default MinimizedComponent
